<template lang="pug">
div
  .sidebar-layout__toolbar
    v-datepicker.mr-2(
        position-right
        v-model="params.planned_on")

    v-btn(
      v-permission-hide="permissions.create_timetable"
      @click="modals.showCreatePlanningModal = true")
      v-icon(
        position-left
        font-size="1"
        icon="plus")
      span {{ 'actions.create_mobile' | translate }}

  v-dialog(
    max-width="480"
    v-model="modals.showCreatePlanningModal")
    create-planning-modal(
      :params="params"
      @close="closeModalCreate")

  v-dialog(
    max-width="480"
    :value="selectedRow !== null"
    @input="selectedRow = null")
    edit-planning-modal(
      :item="selectedRow"
      @close="onEdit")

  //- FOR DESKTOP AND TABLETS
  template(v-if="!isMobile")
    planning-table(
      :loading="loading"
      @select="onRowSelect")

  //- FOR MOBILE ONLY
  template(v-if="isMobile")
    planning-table-mobile(
      :loading="loading")

  template(
    v-if="!loading && showPagination")
    v-paginate(
      v-model="page"
      :page-count="pageCount"
      :click-handler="pageChangeHandler")

</template>

<script>
import { mapActions } from 'vuex'

import { formateDate } from '@/util/utils'
import permissions from '@/util/permissions'

import paginationMixin from '@/mixins/pagination.mixin'
import adaptationMixin from '@/mixins/adaptation.mixin'

import CreatePlanningModal from './modals/CreatePlanningModal'
import EditPlanningModal from './modals/EditPlanningModal'

const PlanningTable = () => import('./PlanningTable')
const PlanningTableMobile = () => import('./PlanningTableMobile')

export default {
  name: 'FarmPlanning',

  mixins: [adaptationMixin, paginationMixin],

  components: {
    CreatePlanningModal,
    EditPlanningModal,
    PlanningTable,
    PlanningTableMobile
  },

  async mounted () {
    await this.setupPagination(params => this.fetchTimetables(
      { ...params, ...this.params })
    )
  },

  data: () => ({
    params: {
      planned_on: formateDate(new Date())
    },
    modals: {
      showCreatePlanningModal: false
    },
    selectedRow: null,
    loading: true,
    permissions: permissions
  }),

  watch: {
    params: {
      async handler () {
        this.loading = true
        await this.setupPagination(params => this.fetchTimetables(
          { ...params, ...this.params })
        )
        if (this.pageCount >= 2) {
          this.page = 1
        }
      },
      deep: true
    }
  },

  methods: {
    ...mapActions(['fetchTimetables']),

    async onEdit () {
      this.selectedRow = null
      await this.setupPagination(params => this.fetchTimetables(
        { ...params, ...this.params })
      )
    },

    onRowSelect (item) {
      this.selectedRow = item
    },

    closeModalCreate () {
      this.modals.showCreatePlanningModal = false
      this.$router.go(0)
    },

    allowViewPlanningOnThisDate () {
      return new Date(this.params.planned_on) <= new Date(formateDate(new Date()))
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
