<template lang="pug">
  v-card
      v-card-title
        h2.card-title {{ 'modals.planning.create.title' | translate }}

      .tabs
        v-btn(
          @click="isShowDefault=true"
          :class="{active: isShowDefault}") {{ "actions.planning_with_silos" | translate }}

        v-btn(
          @click="isShowDefault=false"
          :class="{active: !isShowDefault}") {{ "actions.planning_without_silos" | translate }}

      create-default-modal(
       :params="params"
        v-if="isShowDefault"
        @close="close"
      )

      create-period-modal(
        :params="params"
        v-if="!isShowDefault"
        @close="close"
      )

</template>

<script>
import CreateDefaultModal from './CreateDefaultModal.vue'
import CreatePeriodModal from './CreatePeriodModal.vue'
export default {
  name: 'CreatePlanningModal',
  components: {
    CreateDefaultModal,
    CreatePeriodModal
  },

  props: {
    params: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    isShowDefault: true
  }),

  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.tabs {
  width: 100%;
  margin-top: 10px;
  background: #F8F8F8;
  display: flex;
  justify-content: space-around;
  padding:0;

  .v-btn--default {
    background: none;
    color: #4EAC55;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    border-radius: 0;
    border-bottom: 3px solid #F8F8F8;

    &:hover,
    &.active {
      border-color: #4EAC55;
    }
  }
}
</style>
